import { gql } from '@apollo/client';
import { IParty } from './partiesFragments';

export const IProject = gql`
  fragment IProject on Project {
    id
    name
    key
  }
`;

export const IUserRef = gql`
  fragment IUserRef on User {
    id
    email
    name
  }
`;

export const IUserData = gql`
  fragment IUserData on User {
    id
    oid
    email
    name
    isActive
  }
`;

export const IDocumentFile = gql`
  fragment IDocumentFile on DocumentFile {
    id
    originalFilename
    blobContainer
    blobName
    mimeType
    createdAt
    downloadUrl
  }
`;

export const IDocumentEvent = gql`
  fragment IDocumentEvent on DocumentEvent {
    id
    eventType
    payload
    createdAt
    author {
      persona
      user {
        id
        name
        email
      }
    }
    authorUser {
      id
      name
      email
    }
    subject {
      user {
        id
        name
      }
    }
    version {
      version
      file {
        originalFilename
      }
    }
  }
`;

export const IDocumentVersion = gql`
  fragment IDocumentVersion on DocumentVersion {
    id
    version
    createdAt
    file {
      ...IDocumentFile
    }
    event {
      ...IDocumentEvent
    }
  }
  ${IDocumentFile}
  ${IDocumentEvent}
`;

export const IDocumentDocusign = gql`
  fragment IDocumentDocusign on DocumentDocusign {
    id
    documentVersionId
    envelopeId
    createdAt
    envelope {
      status
      voidedReason
      recipients {
        signers {
          email
          name
          status
          routingOrder
        }
        carbonCopies {
          email
          name
          status
          routingOrder
        }
      }
      completedDateTime
      createdDateTime
      deliveredDateTime
      lastModifiedDateTime
      statusChangedDateTime
      expireDateTime
      expireEnabled
    }
  }
`;

export const IContainerUser = gql`
  fragment IContainerUser on ContainerUser {
    user {
      ...IUserRef
    }
  }
  ${IUserRef}
`;

export const ILegalFolderUser = gql`
  fragment ILegalFolderUser on LegalFolderUser {
    user {
      ...IUserRef
    }
  }
  ${IUserRef}
`;

export const IDocumentUser = gql`
  fragment IDocumentUser on DocumentUser {
    order
    persona
    reviewStatus
    isEnabled
    user {
      ...IUserRef
    }
  }
  ${IUserRef}
`;

export const IDocumentsListDocument = gql`
  fragment IDocumentsListDocument on Document {
    id
    name
    documentType {
      id
      name
    }
    startDate
    endDate
    updatedAt
    createdAt
    status
    owners {
      ...IDocumentUser
    }
    lastAction {
      author {
        user {
          name
        }
      }
    }
    pendingReviewers {
      user {
        name
      }
    }
    currentUserHasActionPending
    canDelete
    container {
      id
      name
      legalFolder {
        id
        name
      }
    }
    docusigns {
      id
    }
  }
  ${IDocumentUser}
`;

export const IDocumentsListDocumentAll = gql`
  fragment IDocumentsListDocumentAll on Document {
    id
    name
    documentType {
      id
      name
    }
    startDate
    endDate
    updatedAt
    createdAt
    status
    owners {
      ...IDocumentUser
    }
    lastAction {
      author {
        user {
          name
        }
      }
    }
    pendingReviewers {
      user {
        name
      }
    }
    currentUserHasActionPending
    container {
      id
      name
      legalFolder {
        id
        name
      }
    }
    divisions {
      id
      name
    }
    statusFolder
    canDelete
    docusigns {
      id
    }
  }
  ${IDocumentUser}
`;

export const IContainersListContainer = gql`
  fragment IContainersListContainer on Container {
    id
    name
    owners {
      ...IContainerUser
    }
    members {
      ...IContainerUser
    }
    endDate
    updatedAt
    createdAt
    documentCount
    currentUserHasActionPending
    documentsDivisions {
      id
      name
    }
    projects {
      id
      key
      name
    }
  }
  ${IContainerUser}
`;

export const IProposalUserRef = gql`
  fragment IProposalUserRef on ProposalUser {
    user {
      id
      oid
      email
      name
      isActive
    }
    isEnabled
  }
`;

export const IProposal = gql`
  fragment IProposal on Proposal {
    id
    name
    owners {
      ...IProposalUserRef
    }
    leadUser {
      ...IProposalUserRef
    }
  }
  ${IProposalUserRef}
`;

export const IContact = gql`
  fragment IContact on Contact {
    id
    name
    email
    phone
  }
`;

export const IDocumentType = gql`
  fragment IDocumentType on DocumentType {
    id
    name
    abbreviation
    order
    isSelectable
  }
`;

export const IDocumentMonetaryTransactionBreakdown = gql`
  fragment IDocumentMonetaryTransactionBreakdown on DocumentMonetaryTransactionBreakdown {
    id
    startTimestamp
    monetaryTransactionValue
  }
`;

export const IProjectWithUsers = gql`
  fragment IProjectWithUsers on Project {
    id
    name
    key
    leader {
      ...IUserData
    }
  }
  ${IUserData}
`;

export const ILegalFolderDocument = gql`
  fragment ILegalFolderDocument on Document {
    id
    name
    createdAt
    updatedAt
    status
    partyReviewStatus
    isPartyReviewRequired
    isOriginParty
    includesIndemnity
    notes
    specialInstructions
    startDate
    endDate
    container {
      id
      name
      owners {
        ...IContainerUser
      }
      projects {
        id
        name
        key
        leader {
          ...IUserData
        }
      }
      legalFolder {
        id
        name
        owners {
          ...ILegalFolderUser
        }
      }
    }
    documentType {
      ...IDocumentType
    }
    contacts {
      ...IContact
    }
    parentDocument {
      id
      name
    }
    childDocuments {
      id
      name
    }
    events {
      ...IDocumentEvent
    }
    owners {
      ...IDocumentUser
    }
    managers {
      ...IDocumentUser
    }
    reviewers {
      ...IDocumentUser
    }
    observers {
      ...IDocumentUser
    }
    versions {
      ...IDocumentVersion
    }
    docusigns {
      ...IDocumentDocusign
    }
    supportFiles {
      ...IDocumentFile
    }
    projects {
      id
      name
      key
      leader {
        ...IUserData
      }
    }
    divisions {
      id
      name
    }
    outsideCounsel
    monetaryTransactionValue
    monetaryTransactionBreakdowns {
      ...IDocumentMonetaryTransactionBreakdown
    }
    monetaryTransactionType
    reviewersReminderMinutePeriod
    statusFolder
    canDocumentBeSigned
    canDelete
  }
  ${IDocumentEvent}
  ${IDocumentType}
  ${IContact}
  ${IDocumentUser}
  ${IDocumentVersion}
  ${IDocumentDocusign}
  ${IDocumentFile}
  ${IDocumentMonetaryTransactionBreakdown}
  ${IUserData}
  ${IContainerUser}
  ${ILegalFolderUser}
`;

export const IDocumentsExportListDocument = gql`
  fragment IDocumentsExportListDocument on Document {
    id
    name
    createdAt
    updatedAt
    status
    partyReviewStatus
    isPartyReviewRequired
    isOriginParty
    includesIndemnity
    notes
    specialInstructions
    startDate
    endDate
    container {
      id
      name
      owners {
        ...IContainerUser
      }
      members {
        ...IContainerUser
      }
      startDate
      endDate
      legalFolder {
        id
        name
        owners {
          user {
            id
            name
          }
        }
        members {
          user {
            id
            name
          }
        }
        parties {
          id
          name
          projectSetupClientCode
        }
      }
    }
    documentType {
      id
      name
    }
    contacts {
      ...IContact
    }
    parentDocument {
      id
      name
    }
    childDocuments {
      id
      name
    }
    events {
      ...IDocumentEvent
    }
    owners {
      ...IDocumentUser
    }
    reviewers {
      ...IDocumentUser
    }
    pendingReviewers {
      user {
        name
      }
    }
    versions {
      ...IDocumentVersion
    }
    supportFiles {
      ...IDocumentFile
    }
    projects {
      id
      name
      key
    }
    divisions {
      id
      name
    }
    outsideCounsel
    monetaryTransactionValue
    monetaryTransactionBreakdowns {
      ...IDocumentMonetaryTransactionBreakdown
    }
    monetaryTransactionType
    statusFolder
    canDocumentBeSigned
    lastAction {
      author {
        user {
          name
        }
      }
    }
  }
  ${IDocumentEvent}
  ${IContact}
  ${IDocumentUser}
  ${IDocumentVersion}
  ${IDocumentFile}
  ${IDocumentMonetaryTransactionBreakdown}
  ${IContainerUser}
`;

export const ILegalFolder = gql`
  fragment ILegalFolder on LegalFolder {
    id
    name
    notes
    owners {
      ...ILegalFolderUser
    }
    members {
      ...ILegalFolderUser
    }
    createdAt
    updatedAt
    currentUserHasActionPending
    parties {
      ...IParty
    }
    currentUserHasAccessRights
    documentsMonetaryTransactionType
    notifyOwners
    notifyMembers
  }
  ${IParty}
  ${ILegalFolderUser}
`;

export const IProjectFolder = gql`
  fragment IProjectFolder on Container {
    id
    name
    status
    notes
    startDate
    endDate
    createdAt
    updatedAt
    projects {
      id
      name
      key
    }
    contacts {
      ...IContact
    }
    proposals {
      ...IProposal
    }
    owners {
      ...IContainerUser
    }
    members {
      ...IContainerUser
    }
    documentCount
    documentsDivisions {
      id
      name
    }
    documentsIndemnities
    documentsMonetaryTransactionValueSum
    documentsMonetaryTransactionType
    legalFolder {
      id
      parties {
        id
      }
      name
    }
    notifyOwners
    notifyMembers
  }
  ${IContact}
  ${IContainerUser}
  ${IProposal}
`;

export const ICountry = gql`
  fragment ICountry on Country {
    id
    code
    name
  }
`;
