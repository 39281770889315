import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

export interface IAIBadgeProps {
  confidencePercent?: number;
}

export const AIBadge: FC<IAIBadgeProps> = ({ confidencePercent }) => {
  return (
    <Box display="inline-flex" alignItems="center" justifyContent="center">
      <Typography
        variant="body1"
        sx={{
          fontSize: '0.6em', // Smanjuje veličinu ikone
          color: '#000099',
          position: 'relative',
          right: '-0.3em',
          top: '-0.7em',
        }}
      >
        AI
        {confidencePercent && confidencePercent < 100 ? `${confidencePercent}%` : undefined}
        <AutoAwesomeIcon
          sx={{
            fontSize: '0.8em', // Smanjuje veličinu ikone
            // color: '#FFD700',
            color: '#000099',
            position: 'absolute',
            right: '-1em',
            top: '-0.3em',
          }}
        />
      </Typography>
    </Box>
  );
};
