import { FC, useCallback } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { uniqueId, startCase, toLower } from 'lodash';

import { UserInitials } from 'components';
import { documentStatusColor } from 'utils/colors';
import { formatTime } from 'utils/time';
import s from './style.module.scss';

import LoadingOverlay from 'react-loading-overlay-ts';
import { IArchiveFileEventWithPayload } from 'graphql/legalFolders/types/IArchiveFileEventWithPayload';
import { ArchiveFileEventType } from 'constants/enums';
import { usePayloadConvert } from './hook/payloadConvertHook';

const changeReportStatusName = (status: string): string => {
  return status;
};

export interface IArchiveFileEventWithPayloadCommentLogProps {
  events: any[];
}

export const ArchiveFileEventsLog: FC<IArchiveFileEventWithPayloadCommentLogProps> = ({
  events = [],
}) => {
  const { payloadConvert, loading: convertedPayloadLoading } = usePayloadConvert({ events });
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  const eventType = (val: IArchiveFileEventWithPayload): JSX.Element => (
    <span style={{ color: documentStatusColor(val) }}>
      {changeReportStatusName(startCase(toLower(val?.eventType)))}
    </span>
  );

  const showDefaultLog = useCallback(
    (val: IArchiveFileEventWithPayload): JSX.Element => (
      <div className={s.eventLogItem}>{eventType(val)}</div>
    ),
    []
  );

  const show = useCallback(
    (title: string, val: IArchiveFileEventWithPayload): JSX.Element => {
      const parsedPayload = payloadConvert(val.payload);

      return (
        <div>
          <div>{title}</div>
          <div style={{ width: '85%', display: parsedPayload?.length ? 'block' : 'none' }}>
            {parsedPayload.map((item) => {
              if (item?.type === 'textareaAutosize') {
                return (
                  <div style={{ display: 'flex', gap: '0.5rem' }}>
                    <div style={{ whiteSpace: 'nowrap' }}>{item.title}:</div>
                    <div
                      className={s.eventLogItem}
                      style={{
                        whiteSpace: 'pre-wrap', // keep text formating
                        border: '1px solid #ccc',
                        padding: '11px 10px',
                        borderRadius: '3px',
                        width: '85%',
                        marginBottom: '0.5rem',
                      }}
                    >
                      {item.value}
                    </div>
                  </div>
                );
              }
              return (
                <div key={item.id} style={{ wordBreak: 'keep-all' }}>
                  {item.title}: {item.value}
                </div>
              );
            })}
          </div>
          <div
            style={{
              width: '85%',
              display: parsedPayload?.length ? 'none' : 'block',
              opacity: '.6',
            }}
          >
            no recorded changes
          </div>
        </div>
      );
    },
    [payloadConvert]
  );

  const content = useCallback(
    (val: IArchiveFileEventWithPayload): JSX.Element => {
      switch (val?.eventType) {
        case ArchiveFileEventType.ARCHIVE_FILE_PARTY_SET:
          return show('Other Party Set', val);
        case ArchiveFileEventType.ARCHIVE_FILE_PARTY_UNSET:
          return show('Other Party Cleared', val);
        case ArchiveFileEventType.ARCHIVE_FILE_SET_DOCUMENT_DATE:
          return show('Document Date Changed', val);

        default:
          return showDefaultLog(val);
      }
    },
    [showDefaultLog, show]
  );

  const filterValues = useCallback((): IArchiveFileEventWithPayload[] => {
    const result = events;
    return result;
  }, [events]);

  const showEvent = useCallback(
    (data: any) => {
      return (
        <Grid xs={12} key={uniqueId()}>
          <div style={{ display: 'flex', gap: '2em', width: '85%' }}>
            <div>
              {!data.payload?.isAuthorAi ? (
                data?.author ? (
                  <UserInitials {...data?.author} />
                ) : (
                  <UserInitials firstName="System" lastName="Application" />
                )
              ) : (
                <UserInitials firstName="Artificial" lastName="Intelligence" />
              )}
            </div>
            <div style={{ flexGrow: 2, display: 'flex', gap: '0.5em', flexDirection: 'column' }}>
              <div className="event-comment-log-header">
                <span className="event-comment-log-header-title">
                  <strong>
                    {!data.payload?.isAuthorAi
                      ? data?.author
                        ? user(data)
                        : 'System Application'
                      : 'AI Author'}
                    {/* &nbsp;-&nbsp;
                      {getVersion(data)} */}
                  </strong>
                </span>
                <span className="event-comment-log-header-date">
                  {isSmall ? <br /> : undefined}
                  {formatTime(data?.createdAt)}
                </span>
              </div>

              <div>{content(data)}</div>
            </div>
          </div>
        </Grid>
      );
    },
    [content, isSmall]
  );

  const user = (data: any): string => (data?.author ? `${data?.author?.name}` : 'Undefined Author');

  return (
    <LoadingOverlay
      spinner
      active={!events || convertedPayloadLoading}
      text="Loading your content..."
    >
      <Grid container padding={0} spacing={2}>
        {events ? (
          events.length ? (
            filterValues()
              .slice()
              .sort((a: any, b: any) => +new Date(b?.createdAt) - +new Date(a?.createdAt))
              .map((event) => showEvent(event))
          ) : (
            <div style={{ padding: '30px' }}>Events log is empty</div>
          )
        ) : undefined}
      </Grid>
    </LoadingOverlay>
  );
};
