import { Tab, Tabs } from '@mui/material';
import React from 'react';
import { FC } from 'react';
import s from './style.module.scss';
import cn from 'classnames';

import { useComponentContext as useFormChangedDialogContext } from 'template/FormChangedDialog/FormChangedDialogContext';

export interface IArchiveFileTabsProps {
  currentView: string;
  changeView: (view: string) => void;
  id: string;
}

export const ArchiveFileTabs: FC<IArchiveFileTabsProps> = ({ currentView, changeView, id }) => {
  const visibleTabs = [
    {
      label: 'Archive File',
      value: ['details'],
    },
    {
      label: 'Event Log',
      value: ['events-log'],
    },
  ];

  const { confirmRequest } = useFormChangedDialogContext();
  return (
    <Tabs
      value={currentView}
      onChange={(e, val) => {
        confirmRequest!((confirmed) => {
          confirmed && changeView!(val);
        });
      }}
      variant="fullWidth"
      className={s.folderTypeTabs}
      TabIndicatorProps={{ style: { background: '#006AD4' } }}
    >
      {visibleTabs.map((tab) => {
        return (
          <Tab
            label={tab.label}
            value={tab.value[0]}
            className={cn(
              s.allTabs,
              tab.value.includes(currentView) ? s.activeTab : s.inactiveTab,
              'paper-title'
            )}
            key={tab.value[0]}
          />
        );
      })}
    </Tabs>
  );
};
