import { useArchiveFileContext } from 'template/ArchiveFile/ArchiveFileContext';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { useUI } from 'contexts/UiContext';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

export interface IRemoveArchivePartyProps {
  onClose: () => void;
  originalFilePath?: string;
}

export const RemoveArchiveParty: FC<IRemoveArchivePartyProps> = ({ onClose, originalFilePath }) => {
  const { addSnackbar } = useUI();
  const { submitUnsetParty, refetch } = useArchiveFileContext();
  const [applyToRootFolder, setApplyToRootFolder] = useState(false);

  const handleSetParty = useCallback(async () => {
    const result: { isError: boolean; errors?: string[] } | undefined = await submitUnsetParty(
      applyToRootFolder
    );
    if (result) {
      const { isError, errors } = result;
      if (isError) {
        addSnackbar!({
          text: 'Error...' + errors?.join(' '),
          severity: 'error',
        });
      } else {
        addSnackbar!({
          text: 'Success',
          severity: 'success',
        });
        refetch();
        onClose();
      }
    }
  }, [addSnackbar, refetch, submitUnsetParty, applyToRootFolder, onClose]);

  return (
    <div style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={applyToRootFolder}
              onChange={() => {
                setApplyToRootFolder!(!applyToRootFolder);
              }}
              name="checkedB"
              color="primary"
            />
          }
          label={
            originalFilePath
              ? `Check to remove party assignment from all files under /${
                  originalFilePath.split('/')[0]
                } folder`
              : `Check to remove party assignment from all files in / folder`
          }
        />
      </div>
      <div style={{ display: 'flex', gap: '50px', justifyContent: 'left' }}>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={debounce(handleSetParty, DEBOUNCE_TIMEOUT)}
          >
            Unset Party
          </Button>
        </div>
        <div>
          <Button variant="contained" color="secondary" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};
