import { Container, Paper, useTheme, useMediaQuery } from '@mui/material';

import './index.scss';

import { ArchiveFilesProvider } from './ArchiveFilesContext';
import { ArchiveFilesView } from './ArchiveFilesView';

export const ArchiveFilesTemplate = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Container
      maxWidth="xl"
      style={
        matches
          ? { paddingLeft: '12px', paddingRight: '12px', paddingTop: '12px', minWidth: '100%' }
          : undefined
      }
    >
      <Paper
        elevation={2}
        style={
          matches
            ? { padding: '4px 4px 16px 4px' }
            : { padding: '4px 4px 16px 4px', marginTop: '6px' }
        }
        id="main-paper"
      >
        <ArchiveFilesProvider>
          <ArchiveFilesView />
        </ArchiveFilesProvider>
      </Paper>
    </Container>
  );
};
