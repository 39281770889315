import { useMemo } from 'react';

export type FieldType =
  | 'label'
  | 'text'
  | '$'
  | 'easyAutoComplete'
  | 'textareaAutosize'
  | 'date'
  | 'msal'
  | 'boolean'
  | 'countryId';

export const useFields = (): { fields: Array<Array<any>> } => {
  const otherFields = useMemo(() => {
    return [
      [
        {
          id: 'partyMapped',
          title: 'Party',
          showColapsed: true,
          field: { type: 'label' as FieldType },
        },
        {
          id: 'createdAt',
          title: 'Created Date',
        },
        {
          id: 'documentDate',
          title: 'Document Date',
        },
        {
          id: 'aiConfidencePercent',
          title: 'AI Confidence',
        },
        {
          id: 'aiExplanation',
          title: 'AI Comment',
        },
      ],
    ];
  }, []);

  return {
    fields: otherFields,
  };
};
