import { useCallback, useEffect, useMemo, useState } from 'react';

import { formatProposalDate } from 'utils/time';
import { currencyFormatUS } from 'utils/currencyFormat';
import { useFields } from './fieldsHook';
import { keyBy, pick } from 'lodash';
import { capitalizeFirstLetter } from 'utils/formats';
import { useSelectedPartyDataItems } from 'hooks/selectedPartiesDataItemsHook';
import { ArchiveFileEventType } from 'constants/enums';
import { IArchiveFileEventWithPayload } from 'graphql/legalFolders/types/IArchiveFileEventWithPayload';

export interface IUsePayloadConvertProps {
  events: IArchiveFileEventWithPayload[];
}

export const usePayloadConvert = ({ events }: IUsePayloadConvertProps) => {
  const { fields } = useFields();

  const [selectedParties, setSelectedParties] = useState<string[]>();
  const { items: partiesItems } = useSelectedPartyDataItems({ IDs: selectedParties });

  useEffect(() => {
    if (events) {
      const partiesToLoad: { [key: string]: boolean } = {};

      events.forEach((event) => {
        if (event.eventType === ArchiveFileEventType.ARCHIVE_FILE_PARTY_SET) {
          const payload = event.payload;

          if (payload.partyId) {
            partiesToLoad[payload.partyId] = true;
          }
        }
      });

      setSelectedParties(Object.keys(partiesToLoad));
    }
  }, [events]);

  const mapSimpleOptions: {
    [bondAttributeType: string]: {
      id: string;
      options: { [id: string]: { [id: string]: string } };
    };
  } = useMemo(
    () => ({
      partyId: {
        id: 'partyMapped',
        options: keyBy(
          partiesItems.map(({ id, name, projectSetupClientCode }) => ({
            id,
            name: `${projectSetupClientCode ? projectSetupClientCode + ' - ' : ''}${name}`,
          })),
          'id'
        ),
      },
    }),
    [partiesItems]
  );

  const arrayToFieldMap = useMemo<{
    [key: string]: { id: string; type: string; options?: any; valueMap?: (obj: any) => string };
  }>(() => ({}), []);

  const fieldsInfo = useMemo(() => {
    const collectedInfo: any[] = [];
    const fieldGroupSections: Array<Array<Array<any>>> = [fields];
    fieldGroupSections.forEach((section) => {
      section.forEach((fieldGroup) => {
        fieldGroup.forEach((field) => {
          if (!!field.id) {
            collectedInfo.push({ ...pick(field, ['id', 'title', 'field']) });
          }
        });
      });
    });
    return keyBy(collectedInfo, 'id');
  }, [fields]);

  const payloadConvert = useCallback(
    (payload: any) => {
      const collected: any[] = [];

      Object.keys(payload).forEach((key) => {
        if (key === 'id') {
          return;
        }

        if (Array.isArray(payload[key as keyof typeof payload])) {
          if (arrayToFieldMap[key as keyof typeof arrayToFieldMap]) {
            const {
              id: mappedId,
              type,
              options,
              valueMap,
            } = arrayToFieldMap[key as keyof typeof arrayToFieldMap];
            if (payload[key].length) {
              collected.push({
                id: key,
                title: fieldsInfo[mappedId]?.title + ' ' + type,
                value:
                  '"' +
                  (valueMap
                    ? payload[key].map((item: any) => valueMap(item)).join('", "')
                    : options
                    ? payload[key]
                        .map((id: string) => {
                          return options[id]?.name || options[id];
                        })
                        .join('", "')
                    : payload[key]) +
                  '"',
              });
            } else {
              // empty list is not important
            }
          } else {
            collected.push({
              id: key,
              title: key,
              value: payload[key].join(', ') || '--',
            });
          }
          return;
        }

        if (fieldsInfo[key]) {
          let displayValue;
          if (
            (!!payload[key] || payload[key] === '' || ['partyId', 'proposalId'].includes(key)) &&
            payload[key] !== payload['previous' + capitalizeFirstLetter(key)] &&
            (payload['previous' + capitalizeFirstLetter(key)] !== null ||
              ['partyId', 'proposalId'].includes(key))
          ) {
            switch (fieldsInfo[key].field?.type) {
              case '$': {
                displayValue = '$ ' + currencyFormatUS(parseFloat(payload[key]), 2);
                break;
              }
              case 'currency': {
                displayValue = currencyFormatUS(parseFloat(payload[key]), 2);
                break;
              }
              case 'date': {
                displayValue = formatProposalDate(payload[key]);
                break;
              }
              default:
                displayValue = payload[key] === null ? '-- clear --' : payload[key];
            }
          } else {
            // displayValue = '--';
            return;
          }

          collected.push({
            id: key,
            title: fieldsInfo[key].title,
            type: fieldsInfo[key].field?.type,
            value: displayValue,
          });
          return;
        }

        if (mapSimpleOptions[key as keyof typeof mapSimpleOptions]) {
          const { id: mappedId, options } = mapSimpleOptions[key as keyof typeof mapSimpleOptions];

          if (
            (!!options && (options[payload[key]]?.name || options[payload[key]])) ||
            payload[key]
          ) {
            collected.push({
              id: mappedId,
              title: fieldsInfo[mappedId].title,
              value:
                (!!options && (options[payload[key]]?.name || options[payload[key]])) ||
                payload[key],
            });
          }

          return;
        }
      });
      return collected;
    },
    [fieldsInfo, mapSimpleOptions, arrayToFieldMap]
  );

  return {
    payloadConvert,
    loading: false,
  };
};
