import { Box, Container, Paper, useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';
import { ArchiveFileTabs } from './components/ArchiveFileTabs/ArchiveFileTabs';
import { Switch, useHistory, useParams } from 'react-router-dom';
import { RouteWithSubRoutes } from 'utils/RouteWithSubRoutes';
import { ArchiveFileLoader } from './ArchiveFileLoader';
import paths from 'constants/paths';

interface IUrlParams {
  id: string;
  tab: string;
}

export interface ISubRoute {
  routes: Array<any>;
}

export const ArchiveFileRoutePage: FC<ISubRoute> = ({ routes }) => {
  const { id, tab } = useParams<IUrlParams>();
  const history = useHistory();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Container
      maxWidth="lg"
      style={{ ...(!matches ? { padding: '0px' } : undefined), marginTop: '30px' }}
    >
      <Box m={4} />
      <div style={{ width: 'fit-content' }}>
        {tab ? (
          <ArchiveFileTabs
            changeView={(newTab) => {
              history.push(
                paths.client.ARCHIVE_FILE_PAGES.replace(':id', id).replace(':tab', newTab)
              );
            }}
            currentView={tab}
            id={id}
          ></ArchiveFileTabs>
        ) : undefined}
      </div>
      <ArchiveFileLoader>
        <Paper
          elevation={3}
          style={{ padding: '2rem', borderTopLeftRadius: '0px' }}
          id="main-paper"
        >
          <Container maxWidth="lg" style={!matches ? { padding: '0px' } : undefined}>
            <Switch>
              {routes.map((route, i) => (
                <RouteWithSubRoutes key={i} {...route} />
              ))}
            </Switch>
          </Container>
        </Paper>
      </ArchiveFileLoader>
    </Container>
  );
};
