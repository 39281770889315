import { orange } from '@mui/material/colors';

export const changeReportStatusName = (status: string): string => {
  if (status === 'Archived') return 'Retired';
  if (status === 'Archive Initiated') return 'Retire Initiated';
  if (status === 'Archive Stored') return 'Retired Document Stored';

  if (status === 'Pending') return 'Action Pending';

  return status;
};

export const statusVariant = (status: any): string => {
  if (status === 'DRAFT') return '#333';
  if (status === 'CREATED') return '#333';
  if (status === 'PENDING') return orange[400]; // '#ffc400';
  if (status === 'APPROVED') return '#006AD4';
  if (status === 'DISAPPROVED') return '#d43624';
  if (status === 'ARCHIVED') return '#00BB13';
  if (status === 'ARCHIVE_INITIATED') return '#00BB13';
  if (status === 'ARCHIVE_STORED') return '#00BB13';
  return '#333';
};

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
export const formatCurrencyUSD = (amount: number): string => currencyFormatter.format(amount);

export const formatCurrencyPartsUSD = (amount: number) => {
  const parts = currencyFormatter.formatToParts(amount);
  const amountParts = parts.filter((part) => !['currency', 'literal'].includes(part.type));
  const currencyPart = parts.find((part) => part.type === 'currency');
  return {
    currencyPart,
    amountPart: amountParts.map(({ type, value }) => value).reduce((string, part) => string + part),
  };
};

export const capitalizeFirstLetter = (string?: string | null) => {
  if (!string) {
    return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const capitalizeAllWordsFirstLetter = (string: string) => {
  return string
    .split(' ')
    .map((word) => capitalizeFirstLetter(word))
    .join(' ');
};

export const enumToArray = (types: any) => {
  var typeArray = Object.keys(types).map(function (type) {
    return types[type];
  });
  return typeArray;
};

export const joinStringArray = (arr: (string | undefined)[]) => {
  const toJoin = arr.filter((item) => !!item);
  if (toJoin.length < 3) {
    return toJoin.join(' and ');
  }
  const allButLast = toJoin.slice(0, -1);
  const last = toJoin.slice(-1)[0];
  return [allButLast.join(', '), last].join(' and ');
};

export const capitalizeAllWordsFirstLetterUS = (string: string) => {
  return string
    .split(/[\s_]+/)
    .map((word) => word.trim())
    .filter((word) => !!word)
    .map((word) => {
      if (['and', 'or', 'of'].includes(word.toLowerCase())) {
        return word;
      } else {
        return capitalizeFirstLetter(word);
      }
    })
    .join(' ');
};

export const convertToFileSize = (bytes?: number | null) => {
  if (!bytes) return '--';

  const kb = 1024;
  const mb = kb * 1024;
  const gb = mb * 1024;

  if (bytes < kb) return `${bytes} B`;
  else if (bytes < mb) return `${(bytes / kb).toFixed(2)} KB`;
  else if (bytes < gb) return `${(bytes / mb).toFixed(2)} MB`;
  else return `${(bytes / gb).toFixed(2)} GB`;
};

export const splitByCapitalLetters = (s: string) => s.split(/(?=[A-Z])/);
