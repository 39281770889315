import { SaveAlt } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
import { FC, useRef, useState } from 'react';
import { useArchiveFileContext } from 'template/ArchiveFile/ArchiveFileContext';

import { convertToFileSize } from 'utils/formats';

import { Edit as EditIcon, Clear as RemoveIcon } from '@mui/icons-material';
import { UpdateArchiveParty } from '../UpdateArchiveFileParty/UpdateArchiveFileParty';
import { RemoveArchiveParty } from '../RemoveArchiveFileParty/RemoveArchiveFileParty';
import { paths } from '../../../../constants';

export const ArchiveFile: FC = () => {
  const { archiveFile } = useArchiveFileContext();

  const [showEditParty, setShowEditParty] = useState(false);
  const [showUnsetParty, setShowUnsetParty] = useState(false);

  const {
    blobSize,
    mimeType,
    originalFileExtension,
    originalFilePath,
    originalFilename,
    party,
    downloadUrl,
    canUpdate,
  } = archiveFile!;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const downloadLinkRef = useRef<HTMLAnchorElement | null>(null);

  return (
    <div>
      <Box mt={4} />
      <Grid container padding={0} spacing={2} alignContent="center" alignItems="center">
        <Grid
          xs={12}
          sm={4}
          style={{
            display: 'flex',
            alignItems: 'center',
            minHeight: matches ? '44px' : undefined,
          }}
        >
          <Typography variant="body2" className="label-title-nocase">
            Other Party Name:
          </Typography>
        </Grid>
        {!showEditParty && !showUnsetParty ? (
          <Grid xs={12} sm={8} style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <div>
              {party?.name ? (
                <a
                  href={paths.client.CLIENT_DETAILS.replace(':id', party.id) || undefined}
                  style={{ color: '#000000de' }}
                  rel="noreferrer"
                >
                  {party.name}
                </a>
              ) : (
                '--'
              )}
            </div>
            {canUpdate ? (
              <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                {party?.name ? (
                  <div>
                    <Tooltip title="View other party removal form">
                      <IconButton
                        size="small"
                        onClick={() => {
                          setShowUnsetParty(true);
                        }}
                      >
                        <RemoveIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </div>
                ) : undefined}
                <div>
                  <Tooltip title="Edit other party assignment">
                    <IconButton
                      size="small"
                      onClick={() => {
                        setShowEditParty(true);
                      }}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            ) : undefined}
          </Grid>
        ) : undefined}
        {showEditParty ? (
          <Grid xs={12} sm={8}>
            <UpdateArchiveParty
              onClose={() => setShowEditParty(false)}
              originalFilePath={originalFilePath}
            />
          </Grid>
        ) : undefined}
        {showUnsetParty ? (
          <Grid xs={12} sm={8}>
            <RemoveArchiveParty
              onClose={() => setShowUnsetParty(false)}
              originalFilePath={originalFilePath}
            />
          </Grid>
        ) : undefined}
      </Grid>
      <Box m={2} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Divider />
            <Box m={2} />
          </Grid>
        </Grid>
      </Grid>
      <Box m={2} />
      <Grid container padding={0} spacing={2} alignContent="center" alignItems="center">
        <Grid
          xs={12}
          sm={4}
          style={{
            display: 'flex',
            alignItems: 'center',
            minHeight: matches ? '44px' : undefined,
          }}
        >
          <Typography variant="body2" className="label-title-nocase">
            File Name:
          </Typography>
        </Grid>
        <Grid xs={12} sm={8}>
          {downloadUrl ? (
            <a
              ref={downloadLinkRef}
              href={downloadUrl || undefined}
              style={{ color: '#000000de' }}
              target="_blank"
              rel="noreferrer"
            >
              {originalFilename}
            </a>
          ) : (
            originalFilename
          )}
        </Grid>
        <Grid
          xs={12}
          sm={4}
          style={{
            display: 'flex',
            alignItems: 'center',
            minHeight: matches ? '44px' : undefined,
          }}
        >
          <Typography variant="body2" className="label-title-nocase">
            Size:
          </Typography>
        </Grid>
        <Grid xs={12} sm={8}>
          {convertToFileSize(blobSize)}
        </Grid>
        <Grid
          xs={12}
          sm={4}
          style={{
            display: 'flex',
            alignItems: 'center',
            minHeight: matches ? '44px' : undefined,
          }}
        >
          <Typography variant="body2" className="label-title-nocase">
            Mime Type:
          </Typography>
        </Grid>
        <Grid xs={12} sm={8}>
          {mimeType}
        </Grid>
        <Grid
          xs={12}
          sm={4}
          style={{
            display: 'flex',
            alignItems: 'center',
            minHeight: matches ? '44px' : undefined,
          }}
        >
          <Typography variant="body2" className="label-title-nocase">
            Extension:
          </Typography>
        </Grid>
        <Grid xs={12} sm={8}>
          {originalFileExtension}
        </Grid>
        <Grid
          xs={12}
          sm={4}
          style={{
            display: 'flex',
            alignItems: 'center',
            minHeight: matches ? '44px' : undefined,
          }}
        >
          <Typography variant="body2" className="label-title-nocase">
            Folder:
          </Typography>
        </Grid>
        <Grid xs={12} sm={8}>
          {originalFilePath || '/'}
        </Grid>
      </Grid>
      {downloadUrl ? (
        <>
          <Box m={2} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Divider />
                <Box m={2} />
              </Grid>
            </Grid>
          </Grid>
          <Box m={3} />
          <Grid container spacing={2} alignContent="center" alignItems="center">
            <Grid
              xs={12}
              sm={4}
              style={{
                display: 'flex',
                alignItems: 'center',
                minHeight: matches ? '44px' : undefined,
              }}
            >
              <Typography variant="body2" className="label-title-nocase">
                File Download:
              </Typography>
            </Grid>
            <Grid xs={12} sm={8}>
              <div
                style={{
                  display: 'flex',
                  alignContent: 'center',
                  alignItems: 'center',
                  justifyContent: 'left',
                  gap: '1em',
                  cursor: 'pointer',
                }}
                onClick={debounce(() => {
                  downloadLinkRef.current?.click();
                }, DEBOUNCE_TIMEOUT)}
              >
                <SaveAlt></SaveAlt>
                <Button variant="outlined" color="primary">
                  Download
                </Button>
              </div>
            </Grid>
          </Grid>
        </>
      ) : undefined}
    </div>
  );
};
