import React, { useRef, useEffect, useState, FC } from 'react';
import s from './style.module.scss';
import { debounce } from 'lodash';

const DEBOUNCE_TIMEOUT = 300;

export interface ILoadMoreProps {
  loadMore: () => void;
}

const LoadMore: FC<ILoadMoreProps> = ({ loadMore }) => {
  const loadMoreRef = useRef<HTMLDivElement>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [divEl, setDivEl] = useState<HTMLDivElement>();

  useEffect(() => {
    if (loadMoreRef.current) {
      setDivEl(loadMoreRef.current);
    }
  }, [loadMoreRef]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !isFetching) {
          setIsFetching(true);
          debounce(loadMore, DEBOUNCE_TIMEOUT)();
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      }
    );

    if (divEl) {
      observer.observe(divEl);
    }

    return () => {
      if (divEl) {
        observer.unobserve(divEl);
      }
    };
  }, [loadMore, isFetching, divEl]);

  useEffect(() => {
    if (isFetching) {
      const timeout = setTimeout(() => setIsFetching(false), DEBOUNCE_TIMEOUT);
      return () => clearTimeout(timeout);
    }
  }, [isFetching]);

  return (
    <div
      className={s.loadMore}
      key="loadMore"
      ref={loadMoreRef}
      style={{ cursor: 'pointer' }}
      onClick={debounce(loadMore, DEBOUNCE_TIMEOUT)}
    >
      Load more...
    </div>
  );
};

export default LoadMore;
