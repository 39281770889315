import { useArchiveFileContext } from 'template/ArchiveFile/ArchiveFileContext';
import { SelectParty } from '../SelectParty/SelectParty';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { useUI } from 'contexts/UiContext';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

export interface IUpdateArchivePartyProps {
  onClose: () => void;
  originalFilePath?: string;
}

export const UpdateArchiveParty: FC<IUpdateArchivePartyProps> = ({ onClose, originalFilePath }) => {
  const { addSnackbar } = useUI();
  const { archiveFile, setArchiveFile, submitSetParty, refetch } = useArchiveFileContext();
  const [applyToRootFolder, setApplyToRootFolder] = useState(false);

  const handleSetParty = useCallback(async () => {
    const result: { isError: boolean; errors?: string[] } | undefined = await submitSetParty(
      applyToRootFolder
    );
    if (result) {
      const { isError, errors } = result;
      if (isError) {
        addSnackbar!({
          text: 'Error...' + errors?.join(' '),
          severity: 'error',
        });
      } else {
        addSnackbar!({
          text: 'Success',
          severity: 'success',
        });
        refetch();
        onClose();
      }
    }
  }, [addSnackbar, refetch, submitSetParty, applyToRootFolder, onClose]);

  return (
    <div style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
      <div>
        <SelectParty
          onSelectChange={(selectedParty) => {
            if (selectedParty) {
              setArchiveFile({ ...archiveFile, selectedParty, partyId: selectedParty.key });
            } else {
              setArchiveFile({
                ...archiveFile,
                selectedParty: undefined,
                partyId: undefined,
              });
            }
          }}
          selected={archiveFile?.selectedParty}
        ></SelectParty>
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={applyToRootFolder}
              onChange={() => {
                setApplyToRootFolder!(!applyToRootFolder);
              }}
              name="checkedB"
              color="primary"
            />
          }
          label={
            originalFilePath
              ? `Check to apply to all files under /${originalFilePath.split('/')[0]} folder`
              : `Check to apply to all files in / folder`
          }
        />
      </div>
      <div style={{ display: 'flex', gap: '50px', justifyContent: 'left' }}>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={debounce(handleSetParty, DEBOUNCE_TIMEOUT)}
            disabled={!archiveFile?.selectedParty}
          >
            Set Party{/* {id ? 'Submit' : 'Create new'} */}
          </Button>
        </div>
        <div>
          <Button variant="contained" color="secondary" onClick={onClose}>
            Cancel{/* {id ? 'Submit' : 'Create new'} */}
          </Button>
        </div>
      </div>
    </div>
  );
};
