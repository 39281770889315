import { Box, Container, Grid } from '@mui/material';
import { FC } from 'react';

import './index.scss';

import { useArchiveFileContext } from './ArchiveFileContext';

import { ArchiveFile } from './components/ArchiveFile/ArchiveFile';

export const ArchiveFileTemplate: FC = () => {
  const { archiveFile } = useArchiveFileContext();

  return (
    <Container maxWidth="md">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box m={5} />
          {archiveFile ? (
            <>
              <ArchiveFile></ArchiveFile>
            </>
          ) : undefined}
        </Grid>
      </Grid>
    </Container>
  );
};
