export enum ProjectStatus {
  PENDING = 'PENDING',
}

export enum DocumentPages {
  DocumentInfo = 'document-info',
  FileManagement = 'file-management',
}

export enum ProjectUserRole {
  PROJECT_LEADER = 'PROJECT_LEADER',
  PROJECT_COORDINATOR = 'PROJECT_COORDINATOR',
  TECHNICAL_COORDINATOR = 'TECHNICAL_COORDINATOR',
}

export enum UserDiscipline {
  ANALYST = 'ANALYST',
  ECONOMIST = 'ECONOMIST',
  ENGINEER = 'ENGINEER',
  GEOLOGIST = 'GEOLOGIST',
  GEOPHYSICIST = 'GEOPHYSICIST',
  OTHER = 'OTHER',
  PETROPHYSICIST = 'PETROPHYSICIST',
}

export enum DocumentStatus {
  IDLE = 'IDLE',
  REPORT_CREATED = 'REPORT_CREATED',
  REPORT_ARCHIVED = 'REPORT_ARCHIVED',
  REPORT_APPROVED = 'REPORT_APPROVED',
  REPORT_REVIEW_REQUESTED = 'REPORT_REVIEW_REQUESTED',
  REPORT_REVIEW_APPROVED = 'REPORT_REVIEW_APPROVED',
  REPORT_REVIEW_DISAPPROVED = 'REPORT_REVIEW_DISAPPROVED',
  REPORT_REVIEW_POSTPONED = 'REPORT_REVIEW_POSTPONED',
}

// export enum DocumentEventType {
//   REPORT_CREATED = 'REPORT_CREATED',
//   REPORT_CANCELED = 'REPORT_CANCELED',
//   REPORT_VERSION_CREATED = 'REPORT_VERSION_CREATED',
//   REPORT_COMMENTED = 'REPORT_COMMENTED',
//   REPORT_REVIEW_REQUESTED = 'REPORT_REVIEW_REQUESTED',
//   REPORT_REVIEW_APPROVED = 'REPORT_REVIEW_APPROVED',
//   REPORT_REVIEW_DISAPPROVED = 'REPORT_REVIEW_DISAPPROVED',
//   REPORT_REVIEW_POSTPONED = 'REPORT_REVIEW_POSTPONED',
//   REPORT_ALL_REVIEWS_COMPLETED = 'REPORT_ALL_REVIEWS_COMPLETED',
//   REPORT_ARCHIVED = 'REPORT_ARCHIVED',

//   REPORT_ARCHIVE_INITIATED = 'REPORT_ARCHIVE_INITIATED',
//   REPORT_ARCHIVE_REPORT_STORED = 'REPORT_ARCHIVE_REPORT_STORED',

//   REPORT_CLIENT_APPROVAL_STATUS_CHANGED = 'REPORT_CLIENT_APPROVAL_STATUS_CHANGED',

//   REPORT_UPDATED = 'REPORT_UPDATED',
//   REPORT_FILE_UPLOADED = 'REPORT_FILE_UPLOADED',
//   REPORT_FILE_DELETED = 'REPORT_FILE_DELETED',
//   REPORT_REVIEW_RECALLED = 'REPORT_REVIEW_RECALLED',
// }

export enum DocumentType {
  PDF = 'PDF',
  DOCX = 'DOCX',
}

export enum DocumentUserStatus {
  IDLE = 'IDLE',
  REVIEW_REQUESTED = 'REVIEW_REQUESTED',
  REVIEW_POSTPONED = 'REVIEW_POSTPONED',
  REVIEW_DISAPPROVED = 'REVIEW_DISAPPROVED',
  REVIEW_APPROVED = 'REVIEW_APPROVED',
}

export enum DocumentOwnership {
  NONE = 'NONE',
  OWNER = 'OWNER',
  STAKEHOLDER = 'STAKEHOLDER',
}

export enum DocumentEventType {
  DOCUMENT_CREATED = 'DOCUMENT_CREATED',
  DOCUMENT_CANCELED = 'DOCUMENT_CANCELED',
  DOCUMENT_OBSERVERS_UPDATED = 'DOCUMENT_OBSERVERS_UPDATED',
  DOCUMENT_OBSERVER_NOTIFICATION_SENT = 'DOCUMENT_OBSERVER_NOTIFICATION_SENT',
  DOCUMENT_VERSION_CREATED = 'DOCUMENT_VERSION_CREATED',
  DOCUMENT_VERSION_DELETED = 'DOCUMENT_VERSION_DELETED',
  DOCUMENT_COMMENTED = 'DOCUMENT_COMMENTED',
  DOCUMENT_COMMENT_REPLIED = 'DOCUMENT_COMMENT_REPLIED',
  DOCUMENT_REVIEW_REQUESTED = 'DOCUMENT_REVIEW_REQUESTED',
  DOCUMENT_REVIEW_APPROVED = 'DOCUMENT_REVIEW_APPROVED',
  DOCUMENT_REVIEW_DISAPPROVED = 'DOCUMENT_REVIEW_DISAPPROVED',
  DOCUMENT_REVIEW_POSTPONED = 'DOCUMENT_REVIEW_POSTPONED',
  DOCUMENT_ALL_REVIEWS_COMPLETED = 'DOCUMENT_ALL_REVIEWS_COMPLETED',
  DOCUMENT_ARCHIVED = 'DOCUMENT_ARCHIVED',

  DOCUMENT_ARCHIVE_INITIATED = 'DOCUMENT_ARCHIVE_INITIATED',
  DOCUMENT_ARCHIVE_DOCUMENT_STORED = 'DOCUMENT_ARCHIVE_DOCUMENT_STORED',

  DOCUMENT_CLIENT_APPROVAL_STATUS_CHANGED = 'DOCUMENT_CLIENT_APPROVAL_STATUS_CHANGED',
  DOCUMENT_PARTY_REVIEW_STATUS_CHANGED = 'DOCUMENT_PARTY_REVIEW_STATUS_CHANGED',

  DOCUMENT_UPDATED = 'DOCUMENT_UPDATED',
  DOCUMENT_FILE_UPLOADED = 'DOCUMENT_FILE_UPLOADED',
  DOCUMENT_FILE_DELETED = 'DOCUMENT_FILE_DELETED',
  DOCUMENT_REVIEW_RECALLED = 'DOCUMENT_REVIEW_RECALLED',
  DOCUMENT_REVIEWERS_UPDATED = 'DOCUMENT_REVIEWERS_UPDATED',

  DOCUMENT_OWNER_CHANGED = 'DOCUMENT_OWNER_CHANGED',
  DOCUMENT_MONETARY_TRANSACTION_BREAKDOWN_CHANGE = 'DOCUMENT_MONETARY_TRANSACTION_BREAKDOWN_CHANGE',

  DOCUMENT_REVIEW_RESENT = 'DOCUMENT_REVIEW_RESENT',

  DOCUMENT_DOCUSIGN = 'DOCUMENT_DOCUSIGN',
  DOCUMENT_DOCUSIGN_CREATED = 'DOCUMENT_DOCUSIGN_CREATED',
  DOCUMENT_DOCUSIGN_ENVELOPE_STATUS_CHANGED = 'DOCUMENT_DOCUSIGN_ENVELOPE_STATUS_CHANGED',
  DOCUMENT_DOCUSIGN_ENVELOPE_DELETE = 'DOCUMENT_DOCUSIGN_ENVELOPE_DELETE',

  DOCUMENT_STATUS_FOLDER_SIGNED = 'DOCUMENT_STATUS_FOLDER_SIGNED',

  DOCUMENT_MOVED = 'DOCUMENT_MOVED',
}

export enum PartyEventType {
  PARTY_CREATED = 'PARTY_CREATED',
  PARTY_UPDATED = 'PARTY_UPDATED',
  PARTY_COMMENT = 'PARTY_COMMENT',
  PARTY_COMMENTED = 'PARTY_COMMENTED',
  PARTY_COMMENT_REPLIED = 'PARTY_COMMENT_REPLIED',
  PARTY_CONTACT_DELETED = 'PARTY_CONTACT_DELETED',
  PARTY_CONTACT_UPDATED = 'PARTY_CONTACT_UPDATED',
  PARTY_CONTACT_CREATED = 'PARTY_CONTACT_CREATED',
  PARTY_STOCK_EXCHANGES_CHANGED = 'PARTY_STOCK_EXCHANGES_CHANGED',
}

export enum ArchiveFileEventType {
  ARCHIVE_FILE_PARTY_SET = 'ARCHIVE_FILE_PARTY_SET',
  ARCHIVE_FILE_PARTY_UNSET = 'ARCHIVE_FILE_PARTY_UNSET',
  ARCHIVE_FILE_SET_DOCUMENT_DATE = 'ARCHIVE_FILE_SET_DOCUMENT_DATE',
}
