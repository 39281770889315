import { useAutoCompleteDataSource } from './autoCompleteDataSource';

import { ArchiveDirectoryFilter } from 'graphql/legalFolders/types/graphql-types';

import {
  ARCHIVE_DIRECTORIES_SEARCH,
  ARCHIVE_DIRECTORIES_SEARCH_COUNT,
} from 'graphql/legalFolders/archiveFiles';
import { archiveDirectories } from 'graphql/legalFolders/types/archiveDirectories';
import { archiveDirectoryCount } from 'graphql/legalFolders/types/archiveDirectoryCount';

const getCount = (count: archiveDirectoryCount) => count.contract_archiveDirectoryCount;
const getItems = (data: archiveDirectories) => {
  return data.contract_archiveDirectories.map((item) => ({ id: item, name: item }));
};
const getItemId = (item: any) => {
  return item;
};
const filterToAPI = (localSearch: string) => ({ originalFilePathContains: localSearch });

export interface IProjectFoldersNamesAutocompleteDataProps {
  search: string;
}

export const useArchiveDirectoriesAutocompleteData = ({
  search,
}: IProjectFoldersNamesAutocompleteDataProps) => {
  const resp = useAutoCompleteDataSource<
    archiveDirectories,
    archiveDirectoryCount,
    { id: string; name: string },
    ArchiveDirectoryFilter
  >({
    GET_QUERY: ARCHIVE_DIRECTORIES_SEARCH,
    GET_COUNT_QUERY: ARCHIVE_DIRECTORIES_SEARCH_COUNT,
    getCount,
    getItems,
    getItemId,
    search,
    filterToAPI,
  });

  return {
    ...resp,
  };
};
