import React from 'react';

// Importing the logo image
import logo from './docusign-logo.png';

// TypeScript props for the component
interface LogoProps {
  style?: any;
  width?: string; // Width of the logo (e.g., '50px', '100%')
  height?: string; // Height of the logo (e.g., '50px', 'auto')
  altText?: string; // Alternative text for accessibility
  className?: string; // Additional class names for custom styling
}

export const DocuSignLogo: React.FC<LogoProps> = ({
  width = '100px',
  height = 'auto',
  altText = 'Logo',
  className = '',
  style,
}) => {
  return <img src={logo} alt={altText} style={{ width, height, ...style }} className={className} />;
};
