import { gql } from '@apollo/client';
import { IArchiveFileEventWithPayload } from './archiveFileEvents';

export const IArchiveFile = gql`
  fragment IArchiveFile on ArchiveFile {
    id
    originalFilename
    originalFileExtension
    originalFilePath
    blobContainer
    blobName
    blobSize
    mimeType
    party {
      id
      name
      projectSetupClientCode
      partyType {
        name
      }
    }
    documentDate
    updatedAt
    createdAt
    events {
      ...IArchiveFileEventWithPayload
    }
  }
  ${IArchiveFileEventWithPayload}
`;

export const IArchiveFileDetails = gql`
  fragment IArchiveFileDetails on ArchiveFile {
    ...IArchiveFile
    downloadUrl
    canUpdate
  }
  ${IArchiveFile}
`;

export const GET_ARCHIVE_FILES = gql`
  query archiveFiles(
    $sort: [ArchiveFileSort!] = []
    $filter: ArchiveFileFilter
    $take: Int = 100
    $skip: Int = 0
  ) {
    contract_archiveFiles(sort: $sort, filter: $filter, take: $take, skip: $skip) {
      ...IArchiveFile
    }
  }
  ${IArchiveFile}
`;

export const GET_ARCHIVE_FILE = gql`
  query archiveFile($id: ID!) {
    contract_archiveFile(id: $id) {
      ...IArchiveFileDetails
    }
  }
  ${IArchiveFileDetails}
`;

export const GET_ARCHIVE_FILES_COUNT = gql`
  query archiveFileCount($filter: ArchiveFileFilter) {
    contract_archiveFileCount(filter: $filter)
  }
`;

export const GET_ARCHIVE_MIME_TYPES = gql`
  query archiveMimeTypes {
    contract_archiveMimeTypes
  }
`;

export const GET_ARCHIVE_FILE_EXTENSIONS = gql`
  query archiveFileExtensions {
    contract_archiveFileExtensions
  }
`;

export const ARCHIVE_DIRECTORIES = gql`
  query listArchiveDirectoriesInPath($path: String!) {
    contract_listArchiveDirectoriesInPath(path: $path)
  }
`;

export const ARCHIVE_DIRECTORIES_SEARCH = gql`
  query archiveDirectories($filter: ArchiveDirectoryFilter, $take: Int = 100, $skip: Int = 0) {
    contract_archiveDirectories(filter: $filter, take: $take, skip: $skip)
  }
`;

export const ARCHIVE_DIRECTORIES_SEARCH_COUNT = gql`
  query archiveDirectoryCount($filter: ArchiveDirectoryFilter) {
    contract_archiveDirectoryCount(filter: $filter)
  }
`;

export const ARCHIVE_FILE_SET_PARTY = gql`
  mutation archiveFileSetParty($partyId: ID!, $filter: ArchiveFileSetPartyFilter) {
    contract_archiveFileSetParty(partyId: $partyId, filter: $filter)
  }
`;

export const ARCHIVE_FILE_UNSET_PARTY = gql`
  mutation archiveFileUnsetParty($filter: ArchiveFileSetPartyFilter) {
    contract_archiveFileUnsetParty(filter: $filter)
  }
`;
