import { useQuery } from '@apollo/client';
import { GET_ARCHIVE_MIME_TYPES } from 'graphql/legalFolders/archiveFiles';
import { archiveMimeTypes } from 'graphql/legalFolders/types/archiveMimeTypes';

import { useCallback, useMemo } from 'react';

export const useArchiveFileMimeTypes = () => {
  const { data, loading } = useQuery<archiveMimeTypes>(GET_ARCHIVE_MIME_TYPES);

  const getArchiveFileMimeTypesPairs = useCallback(() => {
    return (
      data?.contract_archiveMimeTypes.map((mimeType) => {
        return {
          id: mimeType,
          name: mimeType,
          ddLabel: `${mimeType}`,
        };
      }) || []
    );
  }, [data]);

  const archiveFileMimeTypePairs = useMemo(() => {
    return getArchiveFileMimeTypesPairs();
  }, [getArchiveFileMimeTypesPairs]);

  return { data, archiveFileMimeTypePairs, loading };
};
