import { IHeadCell } from 'components/ui/Table/components/TableHead/TableHead';
import { DocumentSortableColumn } from 'graphql/legalFolders/types/graphql-types';
import {
  TransformCurrentlyReviewing,
  TransformCurrentlyReviewingBellIcon,
  TransformDocumentStatus,
  TransformNameAddDocusignLogo,
  TransformOwners,
} from 'utils/TableTransformFunctions';

export const demacProjectFolderDocumentsTableHead: IHeadCell[] = [
  {
    id: 'currentlyReviewing',
    numeric: false,
    disablePadding: false,
    label: 'Action',
    sortable: true,
    sortBy: DocumentSortableColumn.CURRENT_USER_ACTION_PENDING,
    // filter: 'text',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformDataCell: TransformCurrentlyReviewingBellIcon,
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Document Name',
    sortable: true,
    sortBy: DocumentSortableColumn.NAME,
    filter: 'text',
    dataCell: {
      shrink: 'content',
    },
    transformDataCell: TransformNameAddDocusignLogo,
  },
  {
    id: 'documentType',
    numeric: false,
    disablePadding: false,
    label: 'Type',
    sortable: false,
    filter: 'dropdown',
    dataCell: {
      shrink: 'content',
    },
  },
  {
    id: 'lastModifiedDate',
    numeric: false,
    disablePadding: false,
    label: 'Last Changed',
    sortable: true,
    sortBy: DocumentSortableColumn.END_DATE,
    filter: 'date',
    dataCell: {
      shrink: 'content',
    },
    transformFunction: (date) =>
      new Date(date).toLocaleDateString('en-EN', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }),
  },
  {
    id: 'currentlyReviewing',
    numeric: false,
    disablePadding: false,
    label: 'Currently Reviewing',
    sortable: false,
    dataCell: {
      shrink: 'content',
    },
    transformDataCell: TransformCurrentlyReviewing,
  },
  {
    id: 'lastActionBy',
    numeric: false,
    disablePadding: false,
    label: 'Last Action By',
    sortable: false,
    filter: 'text',
    dataCell: {
      shrink: 'content',
    },
  },
  {
    id: 'ownerNames',
    numeric: false,
    disablePadding: false,
    label: 'Owners',
    sortable: false,
    dataCell: {
      shrink: 'content',
    },
    transformDataCell: TransformOwners,
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    sortable: false,
    filter: 'text',
    dataCell: {
      shrink: 'content',
    },
    transformDataCell: TransformDocumentStatus,
  },
];
