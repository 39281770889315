import { gql } from '@apollo/client';

export const IArchiveFileEventPayload = gql`
  fragment IArchiveFileEventPayload on ArchiveFileEventPayload {
    partyId
    documentDate
    isAuthorAi
    aiConfidencePercent
    aiExplanation
  }
`;

export const IArchiveFileEvent = gql`
  fragment IArchiveFileEvent on ArchiveFileEvent {
    eventType
    createdAt
    author {
      id
      name
      email
    }
  }
`;

export const IArchiveFileEventWithPayload = gql`
  fragment IArchiveFileEventWithPayload on ArchiveFileEvent {
    ...IArchiveFileEvent
    payload {
      ...IArchiveFileEventPayload
    }
  }
  ${IArchiveFileEvent}
  ${IArchiveFileEventPayload}
`;
