import { Grid, Typography } from '@mui/material';
import { Box, Container } from '@mui/system';
import { ArchiveFileEventsLog } from './components/ArchiveFileEventsLog';
import { useArchiveFileContext } from 'template/ArchiveFile/ArchiveFileContext';

export const ArchiveFileEvents = () => {
  const { archiveFile } = useArchiveFileContext();

  if (!archiveFile?.id) {
    return <></>;
  }

  return (
    <Container maxWidth="md">
      <Box m={5} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body2" className="label-title-nocase">
            Events/comments log
          </Typography>
        </Grid>
      </Grid>
      <Box m={2} />

      <Grid container spacing={0}>
        <Grid item xs={12} style={{ position: 'relative' }}>
          <ArchiveFileEventsLog events={archiveFile.events ?? []}></ArchiveFileEventsLog>
        </Grid>
      </Grid>
    </Container>
  );
};
