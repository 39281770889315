import { useAutoCompleteDataSource } from './autoCompleteDataSource';

// import { parties, parties_parties } from 'graphql/proposals/types/parties';
import { partyNames, partyNames_parties } from 'graphql/legalFolders/types/partyNames';
import { partyCount } from 'graphql/legalFolders/types/partyCount';
import { PartyFilterInput } from 'graphql/legalFolders/types/graphql-types';

import { GET_PARTY_NAMES, GET_PARTY_COUNT } from 'graphql/legalFolders/parties';

const getCount = (count: partyCount) => count.partyCount;
const getItems = (data: partyNames) => data.parties;
const getItemId = (item: any) => item.id;
const filterToAPI = (localSearch: string) => ({ nameContains: localSearch });

export interface IPartiesNamesAutocompleteDataHook {
  search: string;
}

export const usePartiesNamesAutocompleteDataHook = ({
  search,
}: IPartiesNamesAutocompleteDataHook) => {
  const resp = useAutoCompleteDataSource<
    partyNames,
    partyCount,
    partyNames_parties,
    PartyFilterInput
  >({
    GET_QUERY: GET_PARTY_NAMES,
    GET_COUNT_QUERY: GET_PARTY_COUNT,
    getCount,
    getItems,
    getItemId,
    search,
    filterToAPI,
  });

  return {
    ...resp,
  };
};
