import { useQuery } from '@apollo/client';
import { GET_ARCHIVE_FILE_EXTENSIONS } from 'graphql/legalFolders/archiveFiles';
import { archiveFileExtensions } from 'graphql/legalFolders/types/archiveFileExtensions';
import { useCallback, useMemo } from 'react';

export const useArchiveFileExtensions = () => {
  const { data, loading } = useQuery<archiveFileExtensions>(GET_ARCHIVE_FILE_EXTENSIONS);

  const getArchiveFileExtensionsPairs = useCallback(() => {
    return (
      data?.contract_archiveFileExtensions.map((ext) => {
        return {
          id: ext,
          name: ext,
          ddLabel: `${ext}`,
        };
      }) || []
    );
  }, [data]);

  const archiveFileExtensionPairs = useMemo(() => {
    return getArchiveFileExtensionsPairs();
  }, [getArchiveFileExtensionsPairs]);

  return { data, archiveFileExtensionPairs, loading };
};
