import { ApolloError, useLazyQuery } from '@apollo/client';
import { useTableStorage } from 'components/ui/Table/storage/tableStorageHook';
import { DEFAULT_ROWS_PER_PAGE } from 'constants/config';
import {
  GET_PROJECT_FOLDER_DOCUMENTS_ALL,
  GET_PROJECT_FOLDER_DOCUMENTS_COUNT,
} from 'graphql/legalFolders/documents';

import { getLegalFolderDocumentsAll } from 'graphql/legalFolders/types/getLegalFolderDocumentsAll';
import { getLegalFolderDocumentsCount } from 'graphql/legalFolders/types/getLegalFolderDocumentsCount';
import {
  DocumentFilter,
  DocumentStatus,
  DocumentStatusFolder,
} from 'graphql/legalFolders/types/graphql-types';
import { omit } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { buildInitials } from 'utils/user';

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface IDocumentsTableHookLoadParams {
  order?: SortOrder;
  orderBy?: string | undefined;
  page: number;
  rowsPerPage: number;
  filter?: any;
  filterValues?: any;
}

export interface IDocumentsTableHookProps {
  initFilter?: any;
  overridePageLoadParams?: any;
  initPageLoadParams?: Partial<IDocumentsTableHookLoadParams>;
  myTask: boolean;
  tableStorageKey?: string;
}

export interface IDocumentsTableHookValue {
  totalItems: number;
  documents: any;
  loadPage: (
    order: SortOrder,
    orderBy: string | undefined,
    page: number,
    rowsPerPage: number
  ) => void;
  loading: boolean;
  error: ApolloError | undefined;
  onFilterChange: (filterValues: any) => boolean;
  filterApplied: any;
  pageLoadParams: any;
}

const defaultFilterValues = {
  legalFolder: { id: 'All', name: 'All' },
  projectFolder: { id: 'All', name: 'All' },
};

export const useDocumentsTableHook = ({
  initFilter,
  overridePageLoadParams,
  myTask,
  initPageLoadParams,
  tableStorageKey,
}: IDocumentsTableHookProps): IDocumentsTableHookValue => {
  const { setItem, getItem } = useTableStorage({
    key: tableStorageKey || 'AllDocumentsTable',
  });

  const [totalItems, setTotalItems] = useState(0);
  const [documents, setDocuments] = useState<any[]>([]);
  const [pageLoadParams, setPageLoadParams] = useState<IDocumentsTableHookLoadParams>({
    page: 0,
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
    filter: initFilter,
    ...initPageLoadParams,
    ...getItem(),
  });

  useEffect(() => {
    if (overridePageLoadParams) {
      const { filter } = overridePageLoadParams;
      setPageLoadParams((current) => ({
        ...current,
        ...overridePageLoadParams,
        filter: { ...current.filter, ...filter },
        filterValues: {
          ...defaultFilterValues,
          ...current.filterValues,
          ...overridePageLoadParams.filterValues,
        },
      }));
    }
  }, [overridePageLoadParams]);

  useEffect(() => {
    setItem(pageLoadParams);
  }, [pageLoadParams, setItem]);

  const loadPage = useCallback(
    (order: SortOrder, orderBy: string | undefined, page: number, rowsPerPage: number) => {
      setPageLoadParams((oldPageLoadParams) => ({
        ...oldPageLoadParams,
        order,
        orderBy,
        page,
        rowsPerPage,
      }));
    },
    []
  );

  const [loadDocuments, { called, data, loading, error, refetch }] =
    useLazyQuery<getLegalFolderDocumentsAll>(GET_PROJECT_FOLDER_DOCUMENTS_ALL, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    });

  const [
    loadDocumentsCount,
    {
      called: calledCount,
      data: dataCount,
      loading: loadingCount,
      error: errorCounter,
      refetch: refetchCount,
    },
  ] = useLazyQuery<getLegalFolderDocumentsCount>(GET_PROJECT_FOLDER_DOCUMENTS_COUNT, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    const { rowsPerPage, page, orderBy, order, filter } = pageLoadParams;

    const variables = {
      take: rowsPerPage,
      skip: page * rowsPerPage,
      sort: orderBy ? [{ column: orderBy, order: order }] : undefined,
      filter: { ...filter, onlyCurrentUserDocuments: myTask },
    };

    if (called) {
      refetch!(variables);
    } else {
      loadDocuments({ variables });
    }
  }, [loadDocuments, refetch, called, pageLoadParams, myTask]);

  useEffect(() => {
    if (!loading && data) {
      setDocuments(
        data.contract_documents?.map((projectFolderDocument) => {
          const {
            container,
            id,
            name,
            documentType,
            startDate,
            updatedAt,
            createdAt,
            status,
            endDate: expirationDate,
            owners,
            lastAction,
            pendingReviewers,
            currentUserHasActionPending,
            statusFolder,
            divisions,
            canDelete,
            docusigns,
          } = projectFolderDocument;
          return {
            id,
            name,
            documentType: documentType?.name || '',
            startDate,
            expirationDate,
            lastModifiedDate: updatedAt,
            createdAt,
            currentlyReviewing: {
              currentUserHasActionPending,
              reviewers: pendingReviewers?.map((reviewer) => reviewer.user?.name),
            },
            lastActionBy: lastAction?.author?.user?.name.length
              ? buildInitials(lastAction?.author?.user?.name)
              : '-',
            ownerNames: owners?.filter((owner) => owner.isEnabled).map((owner) => owner.user?.name),
            status,
            legalFolder: container?.legalFolder,
            projectFolder: container ? omit(container, 'legalFolder') : undefined,
            statusFolder,
            divisions,
            canDelete,
            docusigns,
          };
        })
      );
    }
  }, [data, loading]);

  useEffect(() => {
    if (!data && !loading && error) {
      setDocuments([]);
    }
  }, [data, loading, error]);

  useEffect(() => {
    const { filter } = pageLoadParams;

    const variables = {
      filter: { ...filter, onlyCurrentUserDocuments: myTask },
    };

    if (calledCount) {
      refetchCount!(variables);
    } else {
      loadDocumentsCount({ variables });
    }
  }, [loadDocumentsCount, refetchCount, calledCount, pageLoadParams, myTask]);

  useEffect(() => {
    if (!loadingCount && dataCount) {
      setTotalItems(dataCount.contract_documentCount);
    }
  }, [dataCount, loadingCount]);

  const convertSelectedToIds = useCallback((item: { id: string } | undefined) => {
    return item && item.id !== 'All' ? [item.id] : undefined;
  }, []);

  const onFilterChange = useCallback(
    (filterValues: any) => {
      if (pageLoadParams) {
        const newFilter: DocumentFilter = {
          nameContains: filterValues.name || undefined,
          divisionIds: filterValues.divisions || undefined,
          documentTypeIds: filterValues.documentType || undefined,
          statuses: filterValues.status as [DocumentStatus] | undefined,
          pendingReviewersNameContains: filterValues.currentlyReviewing || undefined,
          ownersNameContains: filterValues.ownerNames || undefined,
          statusFolders: filterValues.statusFolder as [DocumentStatusFolder] | undefined,
          legalFolderIds: convertSelectedToIds(filterValues.legalFolder),
          containerIds: convertSelectedToIds(filterValues.projectFolder),
          onlyPartyApprovalPending: filterValues.onlyPartyApprovalPending,
          updatedAt: filterValues.lastModifiedDate || undefined,
          legalFolderNameContains: filterValues.legalFolderName || undefined,
          containerNameContains: filterValues.projectFolderName || undefined,
          projectNameContains: filterValues.projectName || undefined,
          projectKeyContains: filterValues.projectKey || undefined,
          partyNameContains: filterValues.partyName || undefined,
          startDateFrom: filterValues.startDateFrom || undefined,
          startDateTo: filterValues.startDateTo || filterValues.documentPeriod?.to || undefined,
          endDateFrom: filterValues.endDateFrom || filterValues.documentPeriod?.from || undefined,
          endDateTo: filterValues.endDateTo || undefined,
          containerStartDateFrom: filterValues.containerStartDateFrom || undefined,
          containerStartDateTo:
            filterValues.containerStartDateTo || filterValues.projectFolderPeriod?.to || undefined,
          containerEndDateFrom:
            filterValues.containerEndDateFrom ||
            filterValues.projectFolderPeriod?.from ||
            undefined,
          containerEndDateTo: filterValues.containerEndDateTo || undefined,
        };

        if (JSON.stringify(pageLoadParams.filter) !== JSON.stringify(newFilter)) {
          setPageLoadParams((oldPageLoadParams) => ({
            ...oldPageLoadParams,
            page: 0,
            filter: newFilter,
            filterValues,
          }));
          return true;
        }
      }
      return false;
    },
    [pageLoadParams, convertSelectedToIds]
  );

  const filterApplied = useMemo(() => {
    const { filter } = pageLoadParams;
    console.log('filter', JSON.stringify(filter));
    return (
      JSON.stringify(filter) !== '{}' &&
      (!overridePageLoadParams?.filter ||
        JSON.stringify(filter) !== JSON.stringify(overridePageLoadParams?.filter))
    );
  }, [pageLoadParams, overridePageLoadParams?.filter]);

  return {
    totalItems,
    documents,
    loadPage,
    loading: loading || !!loadingCount,
    onFilterChange,
    filterApplied,
    pageLoadParams,
    error: error || errorCounter,
  };
};
