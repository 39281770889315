import { StickyHeaderTable } from 'components/ui/Table/StickyHeaderTable';
import { allArchiveFilesTableHead } from 'constants/allArchiveFilesTable';
import { Box, Button, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import LoadingOverlay from 'react-loading-overlay-ts';
import { ClearAllOutlined as ClearAllOutlinedIcon } from '@mui/icons-material';
import { useArchiveFilesContext } from './ArchiveFilesContext';
import { FC, useEffect, useMemo, useCallback } from 'react';
import { SortOrder } from 'components/ui/Table/components/HeaderCell/HeaderCell';
import { useUI } from 'contexts/UiContext';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

import { apolloErrorHandler } from 'utils/apolloErrorHandler';
import { useArchiveDirectoriesAutocompleteData } from 'hooks/archiveDirectoriesAutocompleteDataHook';
import { usePartiesNamesAutocompleteDataHook } from 'hooks/partiesNamesAutocompleteDataHook';
export interface IArchiveFilesViewProps {
  title?: string;
  onReady?: () => void;
  printView?: boolean;
}

export const ArchiveFilesView: FC<IArchiveFilesViewProps> = ({ onReady, printView }) => {
  const { addSnackbar } = useUI();
  const {
    totalItems,
    documents,
    loadPage,
    pageLoadParams,
    loading,
    onFilterChange,
    filterApplied,
    clearAllFilters,
    onArchiveFileSelect,
    filterOptions,
    filterValues,
    error,
  } = useArchiveFilesContext();

  useEffect(() => {
    if (!!onReady && !loading && documents) {
      onReady();
    }
  }, [loading, documents, onReady]);

  useEffect(() => {
    if (!loading && !!error) {
      apolloErrorHandler(addSnackbar!)(error);
    }
  }, [loading, error, addSnackbar]);

  const partiesNamesProps = usePartiesNamesAutocompleteDataHook({
    search: filterValues['party'],
  });

  const archiveDirectoriesProps = useArchiveDirectoriesAutocompleteData({
    search: filterValues['originalFilePath'],
  });

  const extendedArchiveFilesHead = useMemo(() => {
    allArchiveFilesTableHead.find((item) => item.id === 'party')!.filterProps = partiesNamesProps;
    allArchiveFilesTableHead.find((item) => item.id === 'originalFilePath')!.filterProps =
      archiveDirectoriesProps;

    return allArchiveFilesTableHead;
  }, [archiveDirectoriesProps, partiesNamesProps]);

  const onSelect = useCallback(
    (id: string) => {
      onArchiveFileSelect && onArchiveFileSelect(id);
      return true;
    },
    [onArchiveFileSelect]
  );

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <LoadingOverlay spinner active={loading && !printView} text="Loading your content...">
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Box mt={2} />
          <Grid container spacing={2} padding={'0px'}>
            <Grid xs={12} md={4}></Grid>
            <Grid xs={12} md={4}>
              {filterApplied ? (
                <Box
                  display="flex"
                  justifyContent={matches ? 'center' : 'left'}
                  paddingLeft={matches ? undefined : '10px'}
                >
                  <Button
                    onClick={debounce(clearAllFilters, DEBOUNCE_TIMEOUT)}
                    variant="outlined"
                    size="small"
                    endIcon={<ClearAllOutlinedIcon />}
                  >
                    Clear Filters
                  </Button>
                </Box>
              ) : undefined}
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12}>
          <Box
            style={{
              borderRadius: '1px',
              boxShadow: '0px 2px 5px #00000030',
              border: '1px solid #00000030',
            }}
          >
            <StickyHeaderTable
              totalItems={totalItems}
              dataCells={documents}
              headCells={extendedArchiveFilesHead}
              loadPage={loadPage}
              handleSelect={onSelect}
              filterOptions={filterOptions}
              filterValues={filterValues}
              onFilterChange={onFilterChange}
              initRowsPerPage={pageLoadParams.rowsPerPage}
              printView={printView}
              initOrder={pageLoadParams.order || SortOrder.ASC}
              initOrderBy={pageLoadParams.orderBy}
              initPage={pageLoadParams.page}
            />
          </Box>
        </Grid>
      </Grid>
    </LoadingOverlay>
  );
};
